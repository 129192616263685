<template>
  <span>{{status}}</span>
</template>
<script>
import moment from '@/helpers/moment'
export default {
  name: 'RoundStatus',
  props: ['data'],
  data() {
    return {
      status: null
    }
  },
  methods: {
    statusCountdown() {
      if(this.data.status === 'Open') {

        if(moment().isBetween(this.data.roundDate.open, this.data.roundDate.close)) {

          const diff = moment(this.data.roundDate.close).diff(moment(), 'milliseconds')
          const duration = moment.duration(diff, 'milliseconds')
          let d = moment.duration(duration).days(),
              h = moment.duration(duration).hours(),
              m = moment.duration(duration).minutes(),
              s = moment.duration(duration).seconds()

          if(d > 0) h = (d*24) + h
          if(h < 10) h = `0${h}`
          if(m < 10) m = `0${m}`
          if(s < 10) s = `0${s}`

          this.status = `ปิดรับใน ${h}:${m}:${s}`
          this.$emit('cssStyle', {
            bg: 'bg-success',
            text: 'text-light',
            link: 'text-light',
            btn: 'text-light'
          })
        }else
        if(moment().isBefore(this.data.roundDate.open)) {

          const diff = moment(this.data.roundDate.open).diff(moment(), 'milliseconds')
          const duration = moment.duration(diff, 'milliseconds')
          let d = moment.duration(duration).days(),
              h = moment.duration(duration).hours(),
              m = moment.duration(duration).minutes(),
              s = moment.duration(duration).seconds()

          if(d > 0) h = (d*24) + h
          if(h < 10) h = `0${h}`
          if(m < 10) m = `0${m}`
          if(s < 10) s = `0${s}`

          this.status = `เปิดรับใน ${h}:${m}:${s}`
          this.$emit('cssStyle', {
            bg: 'bg-warning',
            text: 'text-dark',
            link: 'text-primary',
            btn: 'text-primary'
          })
        }else{
          this.status = 'ปิดรับ'
          this.$emit('cssStyle', {
            bg: 'bg-secondary',
            text: 'text-light',
            link: 'text-warning',
            btn: 'text-warning'
          })
        }
      }else
      if(this.data.status === 'Refund'){
        this.status = 'ยกเลิก'
        this.$emit('cssStyle', {
          bg: 'bg-danger',
          text: 'text-light',
          link: 'text-danger',
          btn: 'text-danger'
        })
      }else
      if(this.data.status === 'Complete'){
        this.status = 'ออกผลแล้ว'
        this.$emit('cssStyle', {
          bg: 'bg-warning',
          text: 'text-dark',
          link: 'text-success',
          btn: 'text-secondary'
        })
      }else
      if(this.data.status === 'Paid'){
        this.status = 'จ่ายเงินแล้ว'
        this.$emit('cssStyle', {
          bg: 'bg-primary',
          text: 'text-light',
          link: 'text-primary',
          btn: 'text-primary'
        })
      }else{
        this.status = this.data.status
      }
    }
  },
  mounted() {
    this.statusCountdown()
    clearInterval(this.Interval)
    this.Interval = setInterval(() => {
      this.statusCountdown()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  }
}
</script>
